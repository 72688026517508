@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

section{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;

    background: #0b7494;
}

.content{
    display: flex;
    flex-direction: row;
    width: 750px;
    height: 550px;
    border-radius: 10px;

    background: #fff;
   
}

.left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    /* background: url("abcd.jpg"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.left img{
    width: 100px;
}

.left h1{
    margin-top: 10px;
    font-size: 3em;
    font-weight: 100;
    font-family: 'Bebas Neue', sans-serif;
    color: #fff;
}

.right{
    margin: 20px auto;
    padding: 10px;
}

.inputbox{
    margin: 8px 0px;
    width: 350px;
    height: fit-content;
}

input {
    margin-top: 4px;
    padding: 5px;
    width: 100%;
    height: 28px;
    text-indent: 9px;
    border: none;
    border-radius: 2px;

    background: #9ee6f3;
}
select{
    background: #9ee6f3;

}

input:hover{
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.5);
}

.right .title h2{
    text-align: center;
    font-size: 1.8em;
  
}

.inputbox label{
    font-size: 1em;
}

input::placeholder{
    text-indent: 10px;
}

.create{
    display: flex;
    justify-content: center;
    width: 360px;
}

.create button{
    margin-top: 5px;
    width: 250px;
    height: 35px;
    border: none;
    border-radius: 25px;

    color: #fff;
    background: #000;
}



.additional p span{
    color: rgb(0, 4, 255);
}

.additional p span:hover{
    text-decoration: underline;
}

.or{
    margin-top: 20px;
    font-size: 1em;
    text-align: center;
}

.sign{
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
    
}

button{
    width: 160px;
    height: 35px;
    padding: 5px;
    border-radius: 25px;
    color: #fff;
    background: none;
    border: 2px solid #8507a5;
}
/* Responsiveness:Start */
@media screen and (max-width:780px) {
    .content{
        flex-direction: column;
        width: 400px;
        height: fit-content;
        margin: 20px auto;
    }
    .left{
        height: 250px;
        width: 100%;
        border-radius: 0px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}
@media screen and (max-width:425px) {
    .content{
        width: 100%;
        margin: 0px;
        border-radius: 0px;
    }
    .left{
        width: 100%;
        border-radius: 0px;
    }
}
/* Responsiveness:End */